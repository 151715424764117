import request from '@/utils/request'

// 查询OSS对象存储列表
export function listOss(query) {
  return request({
    url: '/resource/oss/list',
    method: 'get',
    params: query
  })
}

// 查询OSS对象基于id串
export function listByIds(ossId) {
  var ossIds = ossId.filter(function (s) {
    return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
  });
  if (ossIds.length <1 ){
    return new Promise((resolve,reject)=>{
      resolve(
        {
          code:200,
          data:[],
          mag:'无请求参数'
        }
      )
    });
  }
  return request({
    url: '/resource/oss/listByIds/' + ossIds,
    method: 'GET'
  })
}

// 删除OSS对象存储
export function delOss(ossId) {
  return request({
    url: '/resource/oss/' + ossId,
    method: 'delete'
  })
}

