<template>
  <!-- 添加或修改优惠策略对话框 -->
  <el-dialog :title="title" :modelValue="modelValue" @update:model-value="changeModelValue" width="880px"
             append-to-body>
    <el-form ref="discountsStrategyRef" :model="form" :inline="true" :rules="rules" label-width="130px">
      <el-tabs :type="'border-card'" v-model="activeName">
        <el-tab-pane name="base" label="基本信息">
          <el-row>
            <!--              <el-col :span="12">-->
            <!--                <el-form-item label="优先级" prop="priorityLevel">-->
            <!--                  <el-input v-model="form.priorityLevel" placeholder="请输入优先级"/>-->
            <!--                </el-form-item>-->
            <!--              </el-col>-->
            <el-col :span="12">
              <el-form-item label="名称" prop="name" style="width: 100%">
                <el-input v-model="form.name" placeholder="请输入名称" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="策略类型" prop="strategyType">
                <el-radio-group v-model="form.strategyType">
                  <el-radio-button label="0">赠送额</el-radio-button>
                  <el-radio-button label="1">优惠券</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.strategyType == 0">
              <el-form-item :label=discountsTypeName prop="discountsPrice" style="width: 100%">
                <el-input v-model="form.discountsPrice" :placeholder=" '请输入' + discountsTypeName" />
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.strategyType == 1 ">
              <el-form-item label="优惠券" prop="gift" style="width: 100%">
                <el-select v-model="form.gift"
                           multiple
                           collapse-tags
                           collapse-tags-tooltip
                           filterable
                           placeholder="请选择优惠券">
                  <el-option v-for="(item,index) in CouponsList"
                             :key="index"
                             :value="item.id"
                             :label="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="储值面额" prop="reachPrice" style="width: 100%">
                <el-input v-model="form.reachPrice" :placeholder="'请输入' + '储值面额'" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="有效期开始时间" prop="startTime" style="width: 100%">
                <el-date-picker clearable style="width: 100%"
                                v-model="form.startTime"
                                type="datetime"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                placeholder="请选择开始时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="有效期结束时间" prop="endTime" style="width: 100%">
                <el-date-picker clearable style="width: 100%"
                                v-model="form.endTime"
                                type="datetime"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                placeholder="请选择结束时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态" prop="state">
                <el-radio-group v-model="form.state">
                  <el-radio-button label="1">正常</el-radio-button>
                  <el-radio-button label="0">停用</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.remark" style="width: 500px"></el-input>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button :loading="buttonLoading" type="primary" @click="submitForm()">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>

import {
  listDiscountsStrategy,
  addDiscountsStrategy,
  updateDiscountsStrategy
} from "@/api/business/discountsStrategy";

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  form: {
    type: Object,
    default: {}
  },
  rules: {
    type: Object,
    default: {}
  }
});
const title = computed(() => {
    return props.title;
  }
);
const form = computed(() => {
    return props.form;
  }
);
const rules = computed(() => {
  console.log(props.rules);
    return props.rules;
  }
);
const discountsTypeName = ref("优惠金额");
const { proxy } = getCurrentInstance();
const buttonLoading = ref(false);
const emit = defineEmits(["refresh", "refList", "update:modelValue"]);
const CouponsList = ref([]);
const activeName = ref("base");
const queryParams = ref({
  priorityLevel: undefined,
  pageNum: 1,
  pageSize: 10,
  name: undefined,
  strategyType: undefined,
  isGlobal: undefined,
  isFirst: undefined,
  isLimit: undefined,
  degree: undefined,
  strategyNo: undefined,
  discountsType: undefined,
  modul: undefined,
  gift: undefined,
  modulNo: undefined,
  discountsPrice: undefined,
  reachPrice: undefined,
  startTime: undefined,
  endTime: undefined,
  state: undefined,
  radio: "1",
  text: "",
  queryType: 2
});

function changeModelValue(value) {

  emit("update:modelValue", value);
}

function getCoupens() {
  let querylist = JSON.parse(JSON.stringify(queryParams.value));
  querylist.queryType = "1";
  querylist.pageNum = null;
  querylist.pageSize = null;
  listDiscountsStrategy(querylist).then(response => {
    CouponsList.value = response.rows;
  });
}

/** 提交按钮 */
function submitForm(number) {
  proxy.$refs["discountsStrategyRef"].validate(valid => {
    if (valid) {
      buttonLoading.value = true;
      if (form.value.useStore) {
        form.value.useStoreDesc = form.value.useStore.map((item) => {
          return storeOptions.value.filter((obj) => obj.storeId === item)[0].storeName;
        });
        form.value.useStore = form.value.useStore.toString();
        form.value.useStoreDesc = form.value.useStoreDesc.toString();
      }

      if (form.value.gift) {
        form.value.gift = form.value.gift.join(",");
      }
      if (form.value.id != null) {
        updateDiscountsStrategy(form.value).then(response => {
          proxy.$modal.msgSuccess("修改成功");
          cancel();
        }).finally(() => {
          buttonLoading.value = false;
        });
      } else {
        addDiscountsStrategy(form.value).then(response => {
          proxy.$modal.msgSuccess("新增成功");
          cancel();
        }).finally(() => {
          buttonLoading.value = false;
        });
      }
    }
  });

}

function cancel() {
  changeModelValue(false);
  emit("refresh");
  emit("refList");
}
getCoupens();
</script>
<style scoped>

</style>
