import request from '@/utils/request'

// 查询服务项目信息列表
export function listServiceProject(query) {
  return request({
    url: '/business/serviceProject/list',
    method: 'get',
    params: query
  })
}

// 查询服务项目信息详细
export function getServiceProject(id) {
  return request({
    url: '/business/serviceProject/' + id,
    method: 'get'
  })
}

// 新增服务项目信息
export function addServiceProject(data) {
  return request({
    url: '/business/serviceProject',
    method: 'post',
    data: data
  })
}

// 修改服务项目信息
export function updateServiceProject(data) {
  return request({
    url: '/business/serviceProject',
    method: 'put',
    data: data
  })
}

// 删除服务项目信息
export function delServiceProject(id) {
  return request({
    url: '/business/serviceProject/' + id,
    method: 'delete'
  })
}

// 状态修改
export function changeStatus(id, status) {
  const data = {
    id,
    status
  }
  return request({
    url: '/business/serviceProject/changeStatus',
    method: 'put',
    data: data
  })
}

export function listArea() {
  return request({
    url: '/business/serviceProject/area/list',
    method: 'get'
  })
}

export function listOrg() {
  return request({
    url: '/business/serviceProject/org/list',
    method: 'get'
  })
}

export function listOrgByArea(params) {
  return request({
    url: '/business/serviceProject/org/listByArea/' + params,
    method: 'get'
  })
}

export function listWorkday() {
  return request({
    url: '/business/serviceProject/workday/list',
    method: 'get'
  })
}

export function listTimerange() {
  return request({
    url: '/business/serviceProject/timerange/list',
    method: 'get'
  })
}
