import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  return request({
    url: '/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: { username, password, code, uuid }
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/auth/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/user/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// 获取所有门店列表
export function getStoreBase() {
  return request({
    url: '/store/store-base/all',
    method: 'get',
  })
}

// 门店叫号数据
export function getStoreDetail(query) {
  return request({
    url: '/business/call/data',
    method: 'get',
    params: query
  })
}

export function sendCallMessage(query) {
  return request({
    url: '/resource/ws/sendCallMessage?storeId=1710635498257108993&message=请C001到xx服务',
    method: 'get',
  })
}

// 门店叫号数据
export function getPublicityVideo(query) {
  return request({
    url: '/business/call/getPublicityVideo',
    method: 'get',
    params: query
  })
}
