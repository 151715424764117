import request from '@/utils/request'

// 查询权益卡列表
export function listBusinessEquityCard(query) {
  return request({
    url: '/business/businessEquityCard/list',
    method: 'get',
    params: query
  })
}

// 查询权益卡详细
export function getBusinessEquityCard(id) {
  return request({
    url: '/business/businessEquityCard/' + id,
    method: 'get'
  })
}

// 新增权益卡
export function addBusinessEquityCard(data) {
  return request({
    url: '/business/businessEquityCard',
    method: 'post',
    data: data
  })
}

// 修改权益卡
export function updateBusinessEquityCard(data) {
  return request({
    url: '/business/businessEquityCard',
    method: 'put',
    data: data
  })
}

// 删除权益卡
export function delBusinessEquityCard(id) {
  return request({
    url: '/business/businessEquityCard/' + id,
    method: 'delete'
  })
}

// 状态修改
export function changeStatus(id, status) {
  const data = {
    id,
    status
  }
  return request({
    url: '/business/businessEquityCard/changeStatus',
    method: 'put',
    data: data
  })
}
